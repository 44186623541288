<template>
    <fragment>
        <a-form-model ref="form" v-bind="formLayout" :model="form" :rules="formRules">
            <a-row>
                <a-col :span="12">
                    <a-form-model-item label="动用方式">
                        <span style="padding-left: 11px">{{ utilizationMethodText }}</span>
                    </a-form-model-item>
                </a-col>

                <a-col :span="12">
                    <a-form-model-item label="金融机构">
                        <span style="padding-left: 11px">{{ institutionName }}</span>
                    </a-form-model-item>
                </a-col>

                <a-col :span="12">
                    <a-form-model-item label="实际动用金额" prop="actualUsageAmount">
                        <a-input-number
                            v-model="form.actualUsageAmount"
                            :precision="2"
                            placeholder="请输入金额"
                            style="width: 75%"
                        />
                    </a-form-model-item>
                </a-col>

                <a-col :span="12">
                    <a-form-model-item label="完成日期" prop="handleFinishDate">
                        <a-date-picker
                            v-model="form.handleFinishDate"
                            value-format="YYYY-MM-DD"
                            :disabled-date="getDisabledDate"
                            style="width: 75%"
                        />
                    </a-form-model-item>
                </a-col>

                <a-col :span="12">
                    <a-form-model-item label="支付凭证" prop="payFile">
                        <custom-upload-file v-model="form.payFile" :file-number="1" />
                    </a-form-model-item>
                </a-col>

                <a-col :span="24">
                    <a-form-model-item
                        label="处理说明"
                        prop="handleDescription"
                        :label-col="{ span: 3 }"
                        :wrapper-col="{ span: 21 }"
                    >
                        <a-textarea
                            v-model="form.handleDescription"
                            :rows="6"
                            style="width: 90%"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>
        </a-form-model>

        <custom-portal :to="portalTo">
            <a-button
                type="primary"
                :loading="confirming"
                @click.stop="handleConfirm"
            >
                确认
            </a-button>
        </custom-portal>
    </fragment>
</template>
<script>
import {
    Row as ARow,
    Col as ACol,
    Input as AInput,
    Button as AButton,
    FormModel as AFormModel,
    DatePicker as ADatePicker,
    InputNumber as AInputNumber
} from 'ant-design-vue'
import Fragment from '@components/Fragment'
import CustomPortal from '@components/CustomPortalVue/Portal'
import CustomUploadFile from '@weights/CreateForm/components/UploadFile'

import { dateUtil } from '4d-space-bag'
import { empty as emptyFilter } from '@utils'
import formatDate from '@/utils/formatDate'

import submitProcessResultService from '@service/marginManagement/marginUtilization/submitProcessResultService'

const ATextarea = AInput.TextArea

const RQUIRED_FIELDS = ['actualUsageAmount', 'handleFinishDate', 'payFile']

function generateFormRules() {
    const rules = {}

    const len = RQUIRED_FIELDS.length
    for (let i = 0; i < len; i++) {
        rules[RQUIRED_FIELDS[i]] = [
            {
                required: true,
                message: '此项为必填'
            }
        ]
    }

    return rules
}

export const PORTAL_KEY = 'ResultEntryModal'

export default {
    name: 'ResultEntryModalContent',
    components: {
        ARow,
        ACol,
        AButton,
        ATextarea,
        AFormModel,
        ADatePicker,
        AInputNumber,
        Fragment,
        CustomPortal,
        CustomUploadFile,
        AFormModelItem: AFormModel.Item
    },
    props: {
        utilizationInfo: {
            type: Object,
            required: true,
            default: () => ({})
        }
    },
    data() {
        return {
            portalTo: PORTAL_KEY,

            confirming: false,
            form: {},
            formLayout: {
                labelCol: {
                    span: 6
                },
                wrapperCol: {
                    span: 18
                }
            },
            formRules: generateFormRules()
        }
    },
    computed: {
        // 金融机构
        institutionName() {
            return emptyFilter(this.utilizationInfo.institutionName)
        },
        // 动用方式
        utilizationMethodText() {
            return emptyFilter(this.utilizationInfo.usageRecordModeText)
        },
        // 动用发起日期
        usageInitiateDate() {
            return this.utilizationInfo.usageInitiateDate
        }
    },
    methods: {
        async handleSubmit() {
            try {
                this.confirming = true

                await submitProcessResultService(this.getFormData())

                this.$emit('on-ok')
                this.$message.success('操作成功')
            } catch (e) {
                this.$message.warning(e.message)
                this.$captureException(e)
            } finally {
                this.confirming = false
            }
        },
        getFormData() {
            return {
                ...this.form,
                id: this.utilizationInfo.id
            }
        },
        getDisabledDate(currentDate) {
            const usageInitiateDate = formatDate(this.usageInitiateDate)
            return dateUtil.compareTime(formatDate(currentDate), usageInitiateDate) > 0
        },
        handleConfirm() {
            this.$refs.form.validate(valid => {
                if (!valid) return

                this.handleSubmit()
            })
        }
    }
}
</script>
